<template>
   <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3> 
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    
                    <!-- Section Detail Start -->

                    <!--information_general start-->
                    <table class="comparateur2 text-left" v-if="key == 'detail_general_information' && item.show">
                        <tr v-if="data.atu_france['motif']">
                            <th width="35%">{{$t('Motif')}}</th>
                            <td>{{$t('atu_motif.'+data.atu_france['motif'])}}</td>
                        </tr>
                        <tr v-if="data['ma_date']">
                            <th width="35%">{{$t('Date_AMM')}}</th>
                            <td>{{DDMMMYY(data['ma_date'])}}</td>
                        </tr>
                        <tr v-if="data.atu_france['type_aap']">
                            <th width="35%">{{$t("Type d'AAP")}}</th>
                            <td>{{check_empty_with_locale(data.atu_france['type_aap'])}}</td>
                        </tr>
                        <tr v-if="data.atu_france['date_depot_ct']">            
                            <th width="35%">{{$t('TRS_date_depot')}} ({{$t('AAP')}})</th>
                            <td>{{DDMMMYY(data.atu_france['date_depot_ct'])}}</td>
                        </tr>
                        <tr v-if="data.atu_france['date_avis_ct']">
                            <th width="35%">{{$t("Date d'avis CT")}} ({{$t('AAP')}})</th>
                            <td>{{DDMMMYY(data.atu_france['date_avis_ct'])}}</td>
                        </tr>
                        <tr v-if="data.rembursement_process_date">
                            <th width="35%">{{$t('Date de la décision du collège')}} ({{$t('AAP')}})</th>
                            <td>{{DDMMMYY(data.rembursement_process_date)}}</td>
                        </tr>
                    </table>
                    <!--information_general end-->   

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                    <!--Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                    <!--Conclusion end-->

                    <!-- evaluation_essais_clinique start--> 
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!-- evaluation_essais_clinique end-->

                    <!--detail_avis start-->
                    <div class="content-onglets" v-else-if="key == 'detail_avis' && item.show">
                        <table class="comparateur2">
                            <tr>
                                <th>{{$t('avis')}}</th>
                                <th>Date</th>
                                <th>Link</th>
                                <th>PDF</th>
                            </tr>
                            <tr>
                                <td>{{check_empty_with_locale(data.atu_france['avis'])}}</td>
                                <td>{{check_empty(DDMMMYY(data.atu_france['date_avis']))}}</td>
                                <td>
                                    <LinkUpload :href="'/uploads/AtuFrance/'+data.drug_id+'/pdf_file.pdf'" v-if="data.atu_france['pdf_file']" target="_blank" rel="noopener">
                                        <div class="icon-fiche"></div>
                                    </LinkUpload>
                                    <span v-else> - </span>
                                </td>
                                <td>
                                    <a :href="data.atu_france['lien_avis']" v-if="data.atu_france['lien_avis']" target="_blank" rel="noopener">
                                        <div class="icon-www"></div>
                                    </a>
                                    <span v-else> - </span>
                                </td>
                            </tr>
                        </table>
                        <p v-html="$i18n.locale === 'fr' ? data.atu_france['avis_commentaire_fr'] : data.atu_france['avis_commentaire_en']"></p> 
                    </div>
                    <!--detail_avis end-->

                    <!--detail_atu start-->
                    <div class="content-onglets" v-else-if="key == 'detail_atu' && item.show">
                        <table class="comparateur2">
                            <tr>
                                <th>{{ data.atu_france['motif'] == '1' && $i18n.locale == 'fr' ? "Date d'octroi ATU" : $t("Date d' octroi ATU")}}</th>
                                <th>{{ data.atu_france['motif'] == '1' && $i18n.locale == 'fr' ? "Date de début de l'ATU" : $t("Date début ATU")}}</th>
                                <th>{{ data.atu_france['motif'] == '1' && $i18n.locale == 'fr' ? "Date de fin de l'ATU" : $t("Date fin ATU")}}</th>
                            </tr>
                            <tr>
                                <td>{{check_empty(DDMMMYY(data.atu_france['date_start']))}}</td>
                                <td>{{check_empty(DDMMMYY(data.atu_france['date_atu_start']))}}</td>
                                <td>{{check_empty(DDMMMYY(data.atu_france['date_end']))}}</td>
                            </tr>
                        </table>
                    </div>
                    <!--detail_atu end-->

                    <!--detail_indication_atu start-->
                    <Indication v-else-if="key == 'detail_indication_atu' && item.show"/>
                    <!--detail_indication_atu end-->

                    <!-- detail_Post_ATU start -->
                    <div class="content-onglets" v-else-if="key == 'detail_Post_ATU' && item.show">
                        <template v-for="(item_post_atu, key_post_atu) in data.atu_france.post_atu" :key="'atu_france_post_atu_'+key_post_atu">
                            <table class="comparateur2">
                                <tr>
                                    <th>Date Post-ATU / Préinscription</th>
                                    <th>{{$t('ma_indication')}}</th>
                                    <th>{{$t('identification_atu')}}</th>
                                </tr>
                                <tr>
                                    <td>{{check_empty(item_post_atu['date'])}}</td>
                                    <td>{{item_post_atu['indication_amm'] ? $t('atu_indication_amm_'+item_post_atu['indication_amm']) : '-'}}</td>
                                    <td>{{item_post_atu['identification'] ? $t('postatu_identification.'+item_post_atu['identification']) : '-'}}</td>
                                </tr>
                            </table>
                            <table class="comparateur2" v-if="item_post_atu['avis_post_pdf'] || item_post_atu['annexe_post_pdf']">
                                <tr v-if="item_post_atu['avis_post_pdf']">
                                    <td>Avis Post-ATU/Préinscription</td>
                                    <td>
                                        <LinkUpload :href="'/uploads/AtuFrance/'+data.drug_id+'/avis_post_pdf.pdf'" target="_blank" rel="noopener">
                                            <div class="icon-fiche"></div>
                                        </LinkUpload>
                                    </td>
                                </tr>
                                <tr v-if="item_post_atu['annexe_post_pdf']" >
                                    <td>Annexe Post-ATU/Préinscription</td>
                                    <td>
                                        <LinkUpload :href="'/uploads/AtuFrance/'+data.drug_id+'/annexe_post_pdf.pdf'" target="_blank" rel="noopener">
                                            <div class="icon-fiche"></div>
                                        </LinkUpload>
                                    </td>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <th>{{$t('indication_amm')}}</th>
                                </tr>
                                <tr>
                                    <td><p v-html="check_empty($i18n.locale === 'fr' ? item_post_atu['indication_amm_fr'] : item_post_atu['indication_amm_en'])"></p></td>
                                </tr>
                                <tr>
                                    <th>Conclusion alternatives thérapeutiques</th>
                                </tr>
                                <tr>
                                    <td><p v-html="check_empty($i18n.locale === 'fr' ? item_post_atu['conclusion_fr'] : item_post_atu['conclusion_en'])"></p></td>
                                </tr>
                                <template v-if="item_post_atu.post_atu_alternative.length > 0">
                                    <tr>
                                        <th>{{$t('post_atu_alternative')}}</th>
                                    </tr>
                                    <tr v-for="(item_post_atu_alternative, key_post_atu_alternative) in item_post_atu.post_atu_alternative" :key="'post_atu_alternative_'+key_post_atu_alternative">
                                        <p v-html="item_post_atu_alternative['text']"></p>
                                    </tr>
                                </template>
                            </table>
                        </template>
                    </div>
                    <!-- detail_Post_ATU end -->

                    <!-- detail_Contribution_Patient start -->
                    <div class="content-onglets" v-else-if="key == 'detail_Contribution_Patient' && item.show">
                        <table class="comparateur2">
                            <template v-for="(item_contribution_patient, key_contribution_patient) in data.atu_france.contribution_patient" :key="'atu_france_post_atu_'+key_contribution_patient">
                                <tr v-if="key_contribution_patient === 0">
                                    <th>{{$t('Expertise externe')}}</th>
                                    <th>Experts</th>
                                </tr>
                                <tr>
                                    <td>{{check_empty_with_locale(item_contribution_patient['expertise_externe'])}}</td>
                                    <td>{{check_empty(item_contribution_patient['nom_de_association'])}}</td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    <!-- detail_Contribution_Patient end -->

                    <!-- detail_Synthese start -->
                    <div class="content-onglets" v-else-if="key == 'detail_Synthese' && item.show">
                        <p v-html="$i18n.locale === 'fr' ? data.atu_france['synthese_fr'] : data.atu_france['synthese_en']"></p> 
                    </div>
                    <!-- detail_Synthese end -->

                    <!-- detail_Contexte start -->
                    <div class="content-onglets" v-else-if="key == 'detail_Contexte' && item.show">
                        <p v-html="$i18n.locale === 'fr' ? data.atu_france['contexte_fr'] : data.atu_france['contexte_en']"></p> 
                    </div>
                    <!-- detail_Contexte end -->

                    <!-- detail_autres_indication start -->
                    <div class="content-onglets" v-else-if="key == 'detail_autres_indication' && item.show">
                        <p v-html="$i18n.locale === 'fr' ? data.atu_france['autres_indication_fr'] : data.atu_france['autres_indication_en']"></p> 
                    </div>
                    <!-- detail_autres_indication end -->

                    <!-- detail_appreciation start -->
                    <div class="content-onglets" v-else-if="key == 'detail_appreciation' && item.show">
                        <template v-if="$i18n.locale === 'fr' ? data.atu_france['gravite_fr'] : data.atu_france['gravite_en']">
                            <div class="text-blank" @click="appreciation['gravite'] = !appreciation['gravite']"> <strong> {{$t('Gravité, rareté, caractère invalidant de la maladie que la spécialité est destinée à traiter')}} : </strong> 
                                <em :class="appreciation['gravite'] ? 'fa fa-angle-up arrow-up' : 'fa fa-angle-down arrow-down'"></em>
                            </div>
                            <div v-if="appreciation['gravite']" style="text-align:justify;overflow: hidden; background-color : #FEF0E6;">
                                <p v-html="check_empty($i18n.locale === 'fr' ? data.atu_france['gravite_fr'] : data.atu_france['gravite_en'])"></p> 
                            </div>
                        </template>
                        
                        <template v-if="($i18n.locale === 'fr' ? data.atu_france['traitements_appropries_introduction_fr'] : data.atu_france['traitements_appropries_introduction_en']) || 
                                        ($i18n.locale === 'fr' ? data.atu_france['traitements_appropries_fr'] : data.atu_france['traitements_appropries_en'])">
                            <div class="text-blank" @click="appreciation['Traitements'] = !appreciation['Traitements']"> <strong> {{$t("Existence de traitement(s) approprié(s)")}} : </strong> 
                                <em :class="appreciation['Traitements'] ? 'fa fa-angle-up arrow-up' : 'fa fa-angle-down arrow-down'"></em>
                            </div>
                            <div v-if="appreciation['Traitements']" style="text-align:justify;overflow: hidden; background-color : #FEF0E6;">
                                <div class="text-blank"> <strong> {{$t('Introduction')}} : </strong></div>
                                <p v-html="check_empty($i18n.locale === 'fr' ? data.atu_france['traitements_appropries_introduction_fr'] : data.atu_france['traitements_appropries_introduction_en'])"></p> 
                            
                                <div class="text-blank"> <strong> Conclusion : </strong></div>
                                <p v-html="check_empty($i18n.locale === 'fr' ? data.atu_france['traitements_appropries_fr'] : data.atu_france['traitements_appropries_en'])"></p> 
                            </div>
                        </template>
                        
                        <template v-if="$i18n.locale === 'fr' ? data.atu_france['mise_en_oeuvre_du_traitement'] : data.atu_france['mise_en_oeuvre_du_traitement_en']">
                            <div class="text-blank" @click="appreciation['mise_en_oeuvre_du_traitement'] = !appreciation['mise_en_oeuvre_du_traitement']"> <strong> {{$t('Mise en œuvre du traitement')}} : </strong> 
                                <em :class="appreciation['mise_en_oeuvre_du_traitement'] ? 'fa fa-angle-up arrow-up' : 'fa fa-angle-down arrow-down'"></em>
                            </div>
                            <div v-if="appreciation['mise_en_oeuvre_du_traitement']" style="text-align:justify;overflow: hidden; background-color : #FEF0E6;">
                                <p v-html="check_empty($i18n.locale === 'fr' ? data.atu_france['mise_en_oeuvre_du_traitement'] : data.atu_france['mise_en_oeuvre_du_traitement_en'])"></p> 
                            </div>
                        </template>

                        <template v-if="$i18n.locale === 'fr' ? data.atu_france['efficacite_et_securite'] : data.atu_france['efficacite_et_securite_en']">
                            <div class="text-blank" @click="appreciation['efficacite_et_securite'] = !appreciation['efficacite_et_securite']"> <strong> {{$t('Efficacité et sécurité fortement présumée de la spécialité')}} : </strong> 
                                <em :class="appreciation['efficacite_et_securite'] ? 'fa fa-angle-up arrow-up' : 'fa fa-angle-down arrow-down'"></em>
                            </div>
                            <div v-if="appreciation['efficacite_et_securite']" style="text-align:justify;overflow: hidden; background-color : #FEF0E6;">
                                <p v-html="check_empty($i18n.locale === 'fr' ? data.atu_france['efficacite_et_securite'] : data.atu_france['efficacite_et_securite_en'])"></p>
                            </div>
                        </template>

                        <template v-if="$i18n.locale === 'fr' ? data.atu_france['caractere_presume_innovant_specialite_fr'] : data.atu_france['caractere_presume_innovant_specialite_en']">
                            <div class="text-blank" @click="appreciation['caractere_presume_innovant_specialite'] = !appreciation['caractere_presume_innovant_specialite']"> <strong> {{$t("Caractère présumé innovant de la spécialité, notamment au regard d'un éventuel comparateur cliniquement pertinent")}} : </strong> 
                                <em :class="appreciation['caractere_presume_innovant_specialite'] ? 'fa fa-angle-up arrow-up' : 'fa fa-angle-down arrow-down'"></em>
                            </div>
                            <div v-if="appreciation['caractere_presume_innovant_specialite']" style="text-align:justify;overflow: hidden; background-color : #FEF0E6;">
                                <p v-html="check_empty($i18n.locale === 'fr' ? data.atu_france['caractere_presume_innovant_specialite_fr'] : data.atu_france['caractere_presume_innovant_specialite_en'])"></p>  
                            </div>
                        </template>

                    </div>
                    <!-- detail_appreciation end -->

                    <!-- detail_strategie_therapeutique start -->
                    <div class="content-onglets" v-else-if="key == 'detail_strategie_therapeutique' && item.show">
                        <p v-html="$i18n.locale === 'fr' ? data.atu_france['strategie_therapeutique_fr'] : data.atu_france['strategie_therapeutique_en']"></p> 
                    </div>
                    <!-- detail_strategie_therapeutique end -->

                    <!-- detail_besoin_therapeutique start -->
                    <div class="content-onglets" v-else-if="key == 'detail_besoin_therapeutique' && item.show">
                        <p v-html="$t(data.atu_france['besoin_therapeutique'])"></p> 
                    </div>
                    <!-- detail_besoin_therapeutique end -->



                    <!-- detail_comparateurs start -->
                    <div class="content-onglets" v-else-if="key == 'detail_comparateurs' && item.show">

                        <div class="text-blank" v-if="($i18n.locale === 'fr' && data.atu_france['comparateurs_introduction_fr']) || ($i18n.locale !== 'fr' && data.atu_france['comparateurs_introduction_en'])">
                            <p v-html="$i18n.locale === 'fr' ? data.atu_france['comparateurs_introduction_fr'] : data.atu_france['comparateurs_introduction_en']"></p> 
                        </div>
                    
                        <div class="text-blank" v-if="($i18n.locale === 'fr' && data.atu_france['comparateurs_medicaments_fr']) || ($i18n.locale !== 'fr' && data.atu_france['comparateurs_medicaments_en'])">
                            <strong> {{$t('Médicaments')}} : </strong>
                            <p v-html="$i18n.locale === 'fr' ? data.atu_france['comparateurs_medicaments_fr'] : data.atu_france['comparateurs_medicaments_en']"></p> 
                        </div>

                        <div class="text-blank" v-if="($i18n.locale === 'fr' && data.atu_france['comparateurs_non_medicamenteux_fr']) || ($i18n.locale !== 'fr' && data.atu_france['comparateurs_non_medicamenteux_en'])"> 
                            <strong> {{$t('Comparateurs non médicamenteux')}} : </strong> 
                            <p v-html="$i18n.locale === 'fr' ? data.atu_france['comparateurs_non_medicamenteux_fr'] : data.atu_france['comparateurs_non_medicamenteux_en']"></p> 
                        </div>

                        <div class="text-blank" v-if="($i18n.locale === 'fr' && data.atu_france['conclusion_fr']) || ($i18n.locale !== 'fr' && data.atu_france['conclusion_en'])"> 
                            <strong> Conclusion : </strong>
                            <p v-html="$i18n.locale === 'fr' ? data.atu_france['conclusion_fr'] : data.atu_france['conclusion_en']"></p> 
                        </div>
                        
                    </div>
                    <!-- detail_comparateurs end -->

                     <!-- detail_donnees_disponibles start -->
                    <div class="content-onglets" v-else-if="key == 'detail_donnees_disponibles' && item.show">
                        <p v-html="$i18n.locale === 'fr' ? data.atu_france['donnees_disponibles_fr'] : data.atu_france['donnees_disponibles_en']"></p> 
                    </div>
                    <!-- detail_donnees_disponibles end -->

                    <!-- detail_recommandation_commission start -->
                    <div class="content-onglets" v-else-if="key == 'detail_recommandation_commission' && item.show">
                        <p v-html="$i18n.locale === 'fr' ? data.atu_france['recommandation_commission_fr'] : data.atu_france['recommandation_commission_en']"></p> 
                    </div>
                    <!-- detail_recommandation_commission end -->

                    <!-- detail_demandes_particulieres start -->
                    <div class="content-onglets" v-else-if="key == 'detail_demandes_particulieres' && item.show">
                        <p v-html="$i18n.locale === 'fr' ?  data.atu_france['demandes_particulieres'] : data.atu_france['demandes_particulieres_en']"></p> 
                    </div>
                    <!-- detail_demandes_particulieres end -->

                    <!-- detail_demandes_de_donnees start -->
                    <div class="content-onglets" v-else-if="key == 'detail_demandes_de_donnees' && item.show">
                        <p v-html="$i18n.locale === 'fr' ? data.atu_france['demandes_de_donnees'] : data.atu_france['demandes_de_donnees_en']"></p> 
                    </div>
                    <!-- detail_demandes_de_donnees end -->

                    <!-- detail_ANSM start -->
                    <div class="content-onglets" v-else-if="key == 'detail_ANSM' && item.show">
                        <table class="comparateur2" v-if="data.atu_france.decision_ansm.length">
                            <tr>
                                <th width="65%">{{$t('Indication revendiquée')}}</th>
                                <th>{{$t('atufrance.ansm.Décision')}}</th>
                            </tr>
                            <tr v-for="(item, key) in data.atu_france.decision_ansm" :key="'ANSM_'+key">
                                <td class="text-left"><span v-html="check_empty($i18n.locale === 'fr' ? item.indication_revendiquee : item.indication_revendiquee_en)"></span></td>
                                <td>{{check_empty_with_locale(item.decision, 'atufrance.ansm.')}}</td>
                            </tr>
                        </table>
                    </div>
                    <!-- detail_ANSM end -->

                    <!-- detail_College start -->
                    <div class="content-onglets" v-else-if="key == 'detail_College' && item.show">
                        <div v-for="(item, keys) in data.atu_france.decision_college" :key="'College_'+keys" class="content-onglets" >
                            <p>
                                <strong>{{$t('Indication revendiquée')}} : </strong>
                                <span v-html="check_empty($i18n.locale === 'fr' ? item.indication_revendiquee : item.indication_revendiquee_en)"></span>
                            </p>
                            <p>
                                <strong>{{$t('Décision')}} : </strong>
                                <span v-html="check_empty_with_locale(item.decision)"></span>
                            </p>
                            <span v-html="$i18n.locale === 'fr' ? item.text_fr : item.text_en"></span>
                            <hr v-if="data.atu_france.decision_college.length > 1 && keys < data.atu_france.decision_college.length - 1">
                        </div>
                    </div>
                    <!-- detail_College end -->

                    <!-- detail_patient_popultion start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_patient_popultion' && item.show">
                        <div class="accordion accordion-table table-block-1200" id="accordion1">
                            <div class="accordion-group">
                                <table class="comparateur2" style="margin-bottom : 0;">
                                    <thead>
                                        <tr> 
                                            <th width="45%">&nbsp;</th>
                                            <th width="18.33%"  class="bg-white text-center">{{$t('atufrance.patient_population.decision')}} </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item_patient_population, key_patient_population) in data.atu_france.patient_population" :key="'patient_popultion_'+key_patient_population">
                                            <tr>
                                                <th width="45%" class="bg-white text-left">
                                                    <span v-html="check_empty($i18n.locale == 'fr' ? item_patient_population.title_fr : item_patient_population.title)"></span>
                                                </th>
                                                <th width="18.33%"  class="bg-white text-center">
                                                    <div>{{item_patient_population.recommendation ? $t('atufrance.patient_population.'+item_patient_population.recommendation) : '-'}}</div>
                                                </th>
                                            </tr>
                                            <tr> 
                                                <td colspan="2" style="position: relative; padding:0px !important;">
                                                    <a class="text-blank" @click="item_patient_population_has[key_patient_population] = !item_patient_population_has[key_patient_population]">
                                                        <em :class="item_patient_population_has[key_patient_population] ? 'icons-2014 icon-minus-s' : 'icons-2014 icon-plus-s'"></em>
                                                    </a>
                                                    <div v-if="item_patient_population_has[key_patient_population]" style="text-align: justify; overflow: hidden; background-color: #FEF0E6; padding: 0.5rem;">
                                                        <span v-html="check_empty($i18n.locale == 'fr' ? item_patient_population['decision_has'] : item_patient_population['decision_has_en'])"></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!--detail_patient_popultion end--> 

                     <!-- detail_Commentaires start -->
                    <div class="content-onglets" v-else-if="key == 'detail_Commentaires' && item.show">
                        <p v-html="$i18n.locale === 'fr' ? data.atu_france['commentaires_fr'] : data.atu_france['commentaires_en']"></p> 
                    </div>
                    <!-- detail_Commentaires end -->

                    <!-- Key Document start-->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!-- Key Document end-->

                    <linkBoitePresentation v-else-if="key == 'detail_Boite' && item.show" :data="data.data_links['BoitePresentation']" :trs_type_demande="''" id="BoitePresentation"/>

                    <!-- Section Detail End -->
                </div>
            </template>

             <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
            
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LinkUpload from '../LinkUpload.vue'
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import linkBoitePresentation from '../linkBoitePresentation.vue'
import { DDMMMYY, check_empty, check_detail_conclusion, key_documents, check_empty_with_locale } from '../../../utils'


export default{
    name: 'AtuFrance',
    components : {
        LinkUpload,
        LeftSection,
        TreatmentLine,
        Conclusion,
        LinkAgency,
        KeyDocument,
        Indication,
        linkBoitePresentation
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_general_information : { 'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},               
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_avis : {'title' : "Commission d'évaluation initiale du rapport bénéfique/risque des produits de santé", 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_atu : {'title' : 'AP/AC', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_indication_atu : {'title' : "Indication retenue dans l'ATU", 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_Post_ATU : {'title' : 'Post-ATU - Pre-Inscription', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Contribution_Patient : {'title' : 'Expertise externe', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Synthese : {'title' : 'Synthèse', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Contexte : {'title' : 'Contexte', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_autres_indication : {'title': 'Autres indications ayant une autorisation temporaire d’utilisation de cohorte (ATUc) non concernées par la demande d’autorisation d’accès précoce', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_appreciation  : {'title': "Appréciation des critères prévus à l'article L.5121-12 du code de la santé publique", 'show' : true, 'enable' : false, 'orange_text':false},
                detail_strategie_therapeutique: {'title': 'Stratégie thérapeutique', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_besoin_therapeutique: {'title': 'Besoin thérapeutique', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_comparateurs: {'title': 'Comparateurs cliniquement pertinents', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_donnees_disponibles : {'title': 'Données disponibles', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_recommandation_commission: {'title': 'Recommandations de la Commission', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_demandes_particulieres: {'title': 'Demandes particulières inhérentes à la prise en charge', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_demandes_de_donnees: {'title': 'Demandes de données', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_ANSM: {'title': 'ANSM', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_patient_popultion: {'title': 'Conclusions de la Commission', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_College: {'title': 'Collège', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Commentaires: {'title': 'Commentaires', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Boite : {'title' : 'Boites_presentation', 'show' : true, 'enable' : false, 'orange_text':false },
            } ,
            appreciation : {},
            item_patient_population_has : {}
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.atu_france){
            
            if(this.data['ma_date'] || this.data.atu_france['motif'] || this.data.atu_france['date_accuse'] || this.data.atu_france['date_deposit'] || 
                this.data.atu_france['date_avis_ansm'] ||  this.data.atu_france['date_examen_adoption'] || this.data.atu_france['date_publication'] || 
                this.data.atu_france['tc_has_id'] || this.data.atu_france['date_ordre_du_jour'] || this.data.atu_france['type_aap'] || 
                this.data.atu_france['date_depot_ct'] || this.data.atu_france['date_validation_admininstrative'] || this.data.atu_france['date_avis_ct'] ||
                (this.$i18n.locale === 'fr' && this.data.atu_france['cond_prescription_delivrance_fr']) || (this.$i18n.locale !== 'fr' && this.data.atu_france['cond_prescription_delivrance_en']))
            { 
                this.list_check_box['detail_general_information'].enable = true
            }

            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }

            if(this.check_detail_conclusion(this.data))
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }

            if(this.data.essais_clinique && this.data.essais_clinique.length > 0)
            { 
                this.list_check_box['detail_clinical_trials'].enable = true 
            }

            if(this.data.atu_france.avis || this.data.atu_france.date_avis || this.data.atu_france.pdf_file || this.data.atu_france.lien_avis || this.data.atu_france['avis_commentaire_fr'] || this.data.atu_france['avis_commentaire_en'])
            {
                this.list_check_box['detail_avis'].enable = true 
            }

            if(this.data.atu_france.date_start || this.data.atu_france.date_atu_start || this.data.atu_france.date_end || this.data.atu_france.line_atu)
            {
                this.list_check_box['detail_atu'].enable = true 
            }

            if((this.$i18n.locale === 'fr' && this.data['indication_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data['indication_en'])){
                this.list_check_box['detail_indication_atu'].enable = true 

                // change title of section Indication retenue dans l’ATU 
                if(this.data.atu_france['motif'] == 'AAP'){
                    this.list_check_box['detail_indication_atu'].title = this.$t("Indication de l'AAP")
                }
                else if(this.data.atu_france['motif'] == 'AAC'){
                    this.list_check_box['detail_indication_atu'].title = "Indication de l'accès précoce"
                }
            }

            if(this.data.atu_france.post_atu && this.data.atu_france.post_atu.length > 0){
                this.list_check_box['detail_Post_ATU'].enable = true
            }

            if(this.data.atu_france.contribution_patient && this.data.atu_france.contribution_patient.length > 0){
                this.list_check_box['detail_Contribution_Patient'].enable = true
            }

            if((this.$i18n.locale === 'fr' && this.data.atu_france['synthese_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['synthese_en'])){
                this.list_check_box['detail_Synthese'].enable = true 
            }

            if((this.$i18n.locale === 'fr' && this.data.atu_france['contexte_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['contexte_en'])){
                this.list_check_box['detail_Contexte'].enable = true 
            }

            if((this.$i18n.locale === 'fr' && this.data.atu_france['autres_indication_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['autres_indication_en'])){
                this.list_check_box['detail_autres_indication'].enable = true 
            }

            if(((this.$i18n.locale === 'fr' && this.data.atu_france['gravite_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['gravite_en'])) ||
                ((this.$i18n.locale === 'fr' && this.data.atu_france['traitements_appropries_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['traitements_appropries_en'])) || 
                ((this.$i18n.locale === 'fr' && this.data.atu_france['traitements_appropries_introduction_fr']) ||
                (this.$i18n.locale !== 'fr' && this.data.atu_france['traitements_appropries_introduction_en'])) ||
                ((this.$i18n.locale === 'fr' && this.data.atu_france['caractere_presume_innovant_specialite_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['caractere_presume_innovant_specialite_en'])) || 
                ((this.$i18n.locale === 'fr' && this.data.atu_france['mise_en_oeuvre_du_traitement']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['mise_en_oeuvre_du_traitement_en'])) ||
                ((this.$i18n.locale === 'fr' &&  this.data.atu_france['efficacite_et_securite']) ||
                (this.$i18n.locale !== 'fr' &&  this.data.atu_france['efficacite_et_securite_en']))){
                this.list_check_box['detail_appreciation'].enable = true 
            }

            if((this.$i18n.locale === 'fr' && this.data.atu_france['strategie_therapeutique_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['strategie_therapeutique_en'])){
                this.list_check_box['detail_strategie_therapeutique'].enable = true 
            }

            if(this.data.atu_france['besoin_therapeutique']){
                this.list_check_box['detail_besoin_therapeutique'].enable = true 
            }

            if((this.$i18n.locale === 'fr' && this.data.atu_france['comparateurs_medicaments_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['comparateurs_medicaments_en']) || 
                (this.$i18n.locale === 'fr' && this.data.atu_france['comparateurs_non_medicamenteux_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['comparateurs_non_medicamenteux_en']) ||
                (this.$i18n.locale === 'fr' && this.data.atu_france['conclusion_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['conclusion_en'])){
                this.list_check_box['detail_comparateurs'].enable = true 
            }


            if((this.$i18n.locale === 'fr' && this.data.atu_france['donnees_disponibles_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['donnees_disponibles_en'])){
                this.list_check_box['detail_donnees_disponibles'].enable = true 
            }

            if((this.$i18n.locale === 'fr' && this.data.atu_france['recommandation_commission_fr']) || 
                (this.$i18n.locale !== 'fr' && this.data.atu_france['recommandation_commission_en'])){
                this.list_check_box['detail_recommandation_commission'].enable = true 
            }

            if((this.$i18n.locale === 'fr' && this.data.atu_france['demandes_particulieres']) ||
                (this.$i18n.locale !== 'fr' &&  this.data.atu_france['demandes_particulieres_en'])){
                this.list_check_box['detail_demandes_particulieres'].enable = true 
            }

            if((this.$i18n.locale === 'fr' && this.data.atu_france['demandes_de_donnees']) ||
                (this.$i18n.locale !== 'fr' && this.data.atu_france['demandes_de_donnees_en'])){
                this.list_check_box['detail_demandes_de_donnees'].enable = true 
            }

            if(this.data.atu_france.decision_ansm.length){
                this.list_check_box['detail_ANSM'].enable = true 
            }

            if(this.data.atu_france.decision_college.length){
                this.list_check_box['detail_College'].enable = true 
            }

            if(this.data.atu_france.patient_population.length){
                this.list_check_box['detail_patient_popultion'].enable = true 
            }

            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length){ 
                this.list_check_box['detail_key_documents'].enable = true
            }

            if(this.data.data_links && this.data.data_links['BoitePresentation']){
                this.list_check_box['detail_Boite'].enable = true
            }

            if((this.$i18n.locale === 'fr' && this.data.atu_france['commentaires_fr']) ||
            (this.$i18n.locale !== 'fr' && this.data.atu_france['commentaires_en'])){
                this.list_check_box['detail_Commentaires'].enable = true
            }

        }
            
    },
    methods : {
        DDMMMYY,
        check_empty,
        check_detail_conclusion,
        key_documents,
        check_empty_with_locale
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }  
    },
}

</script>

<style scoped>
</style>